export const STYLE_BREAKPOINTS = {
  MOBILE: 480,
  TABLET: 640,
  SD: 1024,
};

export const device = {
  mobile: `(max-width: ${STYLE_BREAKPOINTS.MOBILE}px)`,
  tablet: `(max-width: ${STYLE_BREAKPOINTS.TABLET}px)`,
  sd: `(max-width: ${STYLE_BREAKPOINTS.SD}px)`,
};

export { default as theme } from './theme';
