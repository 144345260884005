export { CAMPAIGN_LIST } from './campaign';

export const TD_CONFIG = {
  host: 'in.treasuredata.com',
  database: 'duffy_log',
  writeKey: '5561/009da378c9fb9a70e621669e98a06e16d25041ef',
};

export const KIND_KEY = {
  ISSUE: '0',
  REQUEST: '1',
  CAMPAIGN: '2',
  OTHER: '3',
};

export const KIND_LIST = [
  {
    label: '不具合',
    value: KIND_KEY.ISSUE,
  },
  {
    label: 'ご意見、ご要望',
    value: KIND_KEY.REQUEST,
  },
  {
    label: 'キャンペーン応募',
    value: KIND_KEY.CAMPAIGN,
  },
  {
    label: 'その他',
    value: KIND_KEY.OTHER,
  },
];

export const POST_STATUS_MAP = {
  DEBUG: 'debug',
  INPUT: 'input',
  FETCHING: 'fetching',
  SUCCESS: 'Success',
  ERROR: 'error',
};
