const colors = {
  white: '#fff',
  yellow_1: '#f7c90a',
  yellow_2: '#F2B711',
  gray: '#eae9e8',
  blackStrong: '#000',
  blackMedium: '#333',
  blackMediumLow: '#666',
  blackWeak: '#999',
  error: 'rgba(249, 70, 70, 0.9)',
};

const theme = {
  background: {
    standard: colors.white,
    secondary: colors.gray,
  },
  text: {
    heading: colors.blackStrong,
    standard: colors.blackMedium,
    standardInverted: colors.white,
    primary: colors.yellow_1,
    secondary: colors.blackMediumLow,
    tertiary: colors.blackWeak,
    error: colors.error,
  },
  button: {
    background: colors.yellow_2,
    text: colors.white,
  },
  font: {
    familyEn: 'helvetica, Arial',
  },
};

export default theme;
