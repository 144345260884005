import { observable, action } from 'mobx';
import { KIND_KEY } from '../appConstants';

export default class UserStore {
  @observable
  cuid = null;

  @observable
  uuid = null;

  @observable
  appVersion = null;

  @observable
  os = null;

  @observable
  osVersion = null;

  @observable
  deviceName = null;

  @observable
  kind = KIND_KEY.ISSUE;

  @observable
  campaignLabel = '';

  @observable
  textInput = '';

  @action
  init = param => {
    this.cuid = param.cuid || '';
    this.uuid = param.uuid || '';
    this.appVersion = param.app_version || '';
    this.os = param.os || '';
    this.osVersion = param.os_version || '';
    this.deviceName = param.device_name || '';
  };

  @action
  updateData(key, newData) {
    if (this[key] === undefined) {
      return;
    }

    this[key] = newData;
  }

  shouldShowCampaign() {
    return this.kind === KIND_KEY.CAMPAIGN;
  }

  enableSubmit() {
    // キャンペーン以外は、テキスト入力あればOK
    if (this.kind !== KIND_KEY.CAMPAIGN) {
      return this.textInput !== '';
    }

    // キャンペーンは、キャンペーン選択必須
    return this.campaignLabel !== '';
  }
}
