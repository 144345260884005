import React, { lazy } from 'react';
import PropTypes from 'prop-types';

const Endpoint = lazy(() =>
  import(/* webpackChunkName: "endpoint" */ './containers/Endpoint'),
);
const InputPage = lazy(() =>
  import(/* webpackChunkName: "input" */ './containers/InputPage'),
);
const CompletePage = lazy(() =>
  import(/* webpackChunkName: "complete" */ './containers/CompletePage'),
);
const ErrorPage = lazy(() =>
  import(/* webpackChunkName: "error" */ './containers/ErrorPage'),
);

const Components = {
  Endpoint,
  InputPage,
  CompletePage,
  ErrorPage,
};

const AsyncComponent = props => {
  const { componentName } = props;
  const Component = Components[componentName];
  return <Component {...props} />;
};

AsyncComponent.propTypes = {
  componentName: PropTypes.string.isRequired,
};

export default AsyncComponent;
