import { createGlobalStyle } from 'styled-components';
import { reset } from 'styled-reset';

export default createGlobalStyle`
  ${reset}

  html, body {
    background-color: #fff;
    color: #333;
    width: 100%;
    min-width: 320px;
    min-height: 100%;
    font-size: 16px;
    line-height: 1.7;
    font-family: "Hiragino Kaku Gothic ProN","ヒラギノ角ゴ Pro W3","游ゴシック","Yu Gothic",YuGothic,Meiryo,"メイリオ","Helvetica Neue",Helvetica,Arial,Sans-Serif;

    @media (max-width: 568px) {
      font-size: 14px;
    }
  }

  div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,img, ul, li, header, footer, nav, section, article, a,p, blockquote, span,header,footer,nav,section,article,input,textarea, figure , figcaption, main, pre{
    box-sizing: border-box;
    word-wrap: break-word;
  }

  #app {
    width: 100%;
    height: 100%;
  }
`;
