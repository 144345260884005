import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';

import Routes from './routes';
import UserStore from './stores/userStore';

const stores = {
  userStore: new UserStore(),
};

const render = () => {
  ReactDOM.render(
    <Provider {...stores}>
      <Routes />
    </Provider>,
    document.getElementById('root'),
  );
};

render();
