import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import Loading from './containers/Loading';

import AsyncComponent from './asyncComponent';

const Routes = () => (
  <Router hashType="slash">
    <React.Suspense fallback={<Loading />}>
      <Switch>
        <Route
          exact
          path="/"
          component={props => (
            <AsyncComponent componentName="Endpoint" {...props} />
          )}
        />
        <Route
          path="/input"
          component={props => (
            <AsyncComponent componentName="InputPage" {...props} />
          )}
        />
        <Route
          path="/complete"
          component={props => (
            <AsyncComponent componentName="CompletePage" {...props} />
          )}
        />
        <Route
          path="/error"
          component={props => (
            <AsyncComponent componentName="ErrorPage" {...props} />
          )}
        />
      </Switch>
    </React.Suspense>
  </Router>
);

Routes.displayName = 'Routes';

export default Routes;
