import React from 'react';
import { ThemeProvider } from 'styled-components';

import { theme } from '../../styles';
import GlobalStyle from '../../styles/global-styles';
import Layout from './layout';

const withLayout = WrappedComponent => props => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyle />
      <Layout>
        <WrappedComponent {...props} />
      </Layout>
    </>
  </ThemeProvider>
);

export default withLayout;
