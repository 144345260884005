import styled from 'styled-components';

export default styled.div`
  display: block;
  padding: 2rem 1rem;
  height: 100%;
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
`;
